import cls from "./FloatModal.module.scss"

export const FloatModal = (props) => {
	const { children, onClose } = props

	return (
		<div onClick={onClose} className={`${cls.FloatModal}`}>
			<div onClick={(e) => e.stopPropagation()} className={cls.Content}>
				{children}
			</div>
		</div>
	)
}
