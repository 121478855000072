import { memo } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { modeActions } from "features/modeLogic/model/slice/modeLogicSlice"
import { Subscribe } from "./Subscribe/Subscribe"
import { Install } from "./Install/Install"
import { Special } from "./Special/Special"
import { Installed } from "./Installed/Installed"
import { getAgent, getInstallPossibility, getMode } from "features/modeLogic"

export const InstallWidget = memo((props) => {
	const { installPrompt } = props

	const navigate = useNavigate()
	const dispatch = useDispatch()

	let mode = useSelector(getMode)
	const agent = useSelector(getAgent)
	const installPossibility = useSelector(getInstallPossibility)

	if (installPrompt === "installed") {
		mode = "installed"
	} else if (!installPrompt && agent == null) {
		mode = "impossibleSpecial"
	}

	const onSubscribe = () => {
		window.open("https://t.me/TagLife_Moments")
		navigate("/")
		// eslint-disable-next-line no-restricted-globals
		location.reload()
	}

	const onCloseInstall = () => {
		// при предложении установить - крестик (сохраняется в локалсторейдж)
		dispatch(modeActions.setMode("app"))
		localStorage.setItem("install", true)
	}

	const onInstall = async () => {
		//функция установки приложения
		installPrompt.prompt()
		localStorage.setItem("install", true)
	}

	const onDownload = () => {
		localStorage.setItem("install", true)
		window.open("https://files.taglife.site/install/taglife.apk")
	}
	// console.log(mode)

	if (mode === "install") {
		return (
			<Install
				installPossibility={installPossibility}
				installPrompt={installPrompt}
				onCloseInstall={onCloseInstall}
				onDownload={onDownload}
				onInstall={onInstall}
				agent={agent}
			/>
		)
	} else if (mode === "special") {
		return (
			<Special
				installPossibility={installPossibility}
				onCloseInstall={onCloseInstall}
				onDownload={onDownload}
				onInstall={onInstall}
				agent={agent}
			/>
		)
	} else if (mode === "impossibleSpecial") {
		return <Subscribe onSubscribe={onSubscribe} />
	} else if (mode === "installed") {
		return <Installed />
	}
})
