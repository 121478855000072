import { FloatModal } from "shared/ui/FloatModal"
import { Text, TextAlign, TextSize, TextWeight } from "shared/ui/Text/Text"
import cls from "./GreetingFloat.module.scss"
import whale from "shared/assets/images/whale4.png"
import { Button } from "shared/ui/Button"
import React from "react"

export const GreetingFloat = (props) => {
	const { closeGreetingFloat } = props

	return (
		<FloatModal onClose={closeGreetingFloat}>
			<img className={cls.FloatWhale} src={whale} alt="whale"></img>
			<Text
				className={cls.TitleTextFloat}
				size={TextSize.XL}
				align={TextAlign.LEFT}
				weight={TextWeight.BOLD}
			>
				Привет! Меня зовут кит Вилли
			</Text>
			<Text
				className={cls.TextFloat}
				align={TextAlign.LEFT}
				weight={TextWeight.MED}
			>
				Я покажу, как много в твоей жизни сделано и сколько всего
				впереди!
			</Text>
			<Button
				onClick={closeGreetingFloat}
				className={cls.FloatButton}
				text={"Начать приключение"}
			></Button>
		</FloatModal>
	)
}
