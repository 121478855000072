/* eslint-disable react/self-closing-comp */
import { classNames } from "shared/lib/classNames/classNames"
import "./Loader.scss"

export const Loader = (props) => {
	const { size, style, className } = props

	let cls

	if (size === "small") {
		cls = "small"
	} else {
		cls = "lds-ring"
	}

	return (
		<div style={style} className={classNames(cls, {}, [className])}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	)
}
