import { Card } from "entities/Card"
import cls from "./Deck.module.scss"
import { BlockedCard, sizeEnum } from "entities/Card/ui/Card"

export const Deck = (props) => {
	const { secondFinded, lastFinded } = props

	return (
		<div className={cls.Deck}>
			<div className={cls.FirstCard}>
				<Card
					blocked={secondFinded ? false : BlockedCard.BLACK}
					size={sizeEnum.MICRO}
					text={secondFinded ? secondFinded.text : "test"}
					image={secondFinded ? secondFinded.img : null}
					color={secondFinded ? secondFinded.color : null}
				/>
			</div>
			<div className={cls.SecondCard}>
				<Card
					blocked={lastFinded ? false : BlockedCard.BLACK}
					size={sizeEnum.MICRO}
					text={lastFinded ? lastFinded.text : "test"}
					image={lastFinded ? lastFinded.img : null}
					color={lastFinded ? lastFinded.color : null}
				/>
			</div>
		</div>
	)
}
