import { classNames } from "shared/lib/classNames/classNames"
import { memo } from "react"
import cls from "./Text.module.scss"

export const TextSize = {
	XL: "size_xl", // 32px
	L: "size_l", // 24px
	M: "size_m", // 17px
	S: "size_s", // 14px
	XS: "size_xs", // 12px
	XXS: "size_xxs", //10px
}

export const TextAlign = {
	RIGHT: "right",
	LEFT: "left",
	CENTER: "center",
}

export const TextColor = {
	BLACK: "black",
	SECBLACK: "secblack",
	TERBLACK: "terblack",
	WHITE: "white",
	RED: "red",
}

export const TextWeight = {
	BOLD: "bold", // 700
	SBOLD: "sbold", // 600
	MED: "med", // 500
	REG: "reg", // 400
}

export const Text = memo((props) => {
	const {
		className,
		color = TextColor.BLACK,
		size = TextSize.M,
		align = TextAlign.CENTER,
		weight = TextWeight.MED,
		onClick,
		children,
	} = props

	const mods = {
		[cls[size]]: true,
		[cls[align]]: true,
		[cls[color]]: true,
		[cls[weight]]: true,
	}

	return (
		<div
			onClick={onClick}
			className={classNames(cls.Text, mods, [className])}
		>
			{children}
		</div>
	)
})
