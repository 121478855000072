export const copyTextToClipboard = (text) => {
	// const textarea = document.createElement("textarea");
	// textarea.value = text;
	// document.body.appendChild(textarea);
	// textarea.style.position = "absolute";
	// textarea.style.left = "-9999px";
	// textarea.select();
	// document.execCommand("copy");
	// document.body.removeChild(textarea);
	// console.log("Текст скопирован в буфер обмена:", text);
	const el = document.createElement("textarea")
	el.value = text
	el.setAttribute("readonly", "")
	el.style.position = "absolute"
	el.style.left = "-9999px"
	document.body.appendChild(el)
	const selected =
		document.getSelection().rangeCount > 0
			? document.getSelection().getRangeAt(0)
			: false
	el.select()
	document.execCommand("copy")
	document.body.removeChild(el)
	if (selected) {
		document.getSelection().removeAllRanges()
		document.getSelection().addRange(selected)
	}
	// console.log("Текст скопирован в буфер обмена:", text)
}
