import { classNames } from "shared/lib/classNames/classNames"
import cls from "./Menu.module.scss"
import { Text, TextAlign, TextSize, TextWeight } from "shared/ui/Text/Text"
import pin from "shared/assets/icons/pinMenu.svg"
import unpin from "shared/assets/icons/unpin.svg"
import cross from "shared/assets/icons/cross-24.svg"

export const Menu = (props) => {
	const {
		className,
		isPinned,
		fromPinnedMoment,
		toPinnedMoment,
		toRejectedMoment,
		onCloseSettings,
		cardId,
		style,
	} = props

	return (
		<div className={classNames(cls.Menu, {}, [className])}>
			<div
				className={cls.background}
				onMouseDown={onCloseSettings}
				onTouchStart={onCloseSettings}
			/>
			<div style={style} className={cls.Wrapper}>
				<div
					onClick={() => {
						if (isPinned) {
							onCloseSettings()
							fromPinnedMoment(cardId)
						} else {
							onCloseSettings()
							toPinnedMoment(cardId)
						}
					}}
					className={cls.item}
				>
					<Text
						className={cls.text}
						size={TextSize.S}
						align={TextAlign.LEFT}
						weight={TextWeight.SBOLD}
					>
						{isPinned ? "Открепить" : "Закрепить"}
					</Text>
					<img
						className={cls.icon}
						src={isPinned ? unpin : pin}
						alt="edit"
					/>
				</div>
				<div className={cls.line} />
				<div
					onClick={() => {
						onCloseSettings()
						toRejectedMoment(cardId)
						// console.log(cardId)
					}}
					className={cls.item}
				>
					<Text
						className={cls.text}
						size={TextSize.S}
						align={TextAlign.LEFT}
						weight={TextWeight.SBOLD}
					>
						Неинтересно
					</Text>
					<img className={cls.icon} src={cross} alt="edit" />
				</div>
			</div>
		</div>
	)
}
