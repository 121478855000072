import { useDispatch } from "react-redux"
import { AndroidInstall } from "../AndroidInstall/AndroidInstall"
import { IosInstall } from "../IosInstall/IosInstall"
import { PwaInstall } from "../PwaInstall/PwaInstall"
import { modeActions } from "features/modeLogic/model/slice/modeLogicSlice"

export const Install = (props) => {
	const { installPossibility, agent, onCloseInstall, onDownload, onInstall } =
		props

	const dispatch = useDispatch()

	if (installPossibility) {
		return (
			<PwaInstall
				text={"Ура, TagLife можно скачать!"}
				onCloseInstall={onCloseInstall}
				onInstall={onInstall}
				cross
			/>
		)
	} else if (agent === "ios" && !installPossibility) {
		return <IosInstall onCloseInstall={onCloseInstall} cross />
	} else if (agent === "android" && !installPossibility) {
		return (
			<AndroidInstall
				text={"Ура, TagLife можно скачать на Android!"}
				onCloseInstall={onCloseInstall}
				onDownload={onDownload}
				cross
			/>
		)
	} else {
		dispatch(modeActions.setMode("app"))
	}
}
