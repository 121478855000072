import { createAsyncThunk } from "@reduxjs/toolkit"
import { collection, getDocs } from "firebase/firestore"
import { db } from "app/providers/FirebaseProvider/ui/FirebaseProvider"

export const fetchCollectionData = createAsyncThunk(
	"collection/fetchCollectionData",
	async () => {
		const collectionData = await getDocs(
			collection(db, "collections")
		).then((querySnapshot) => {
			const newData = querySnapshot.docs.map((doc) => ({ ...doc.data() }))
			const data = newData.sort((a, b) => a.id - b.id)
			return data
		})
		return collectionData
	}
)
