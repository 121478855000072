import { classNames } from "shared/lib/classNames/classNames"
import cls from "./Popup.module.scss"
import { memo } from "react"
import whale from "shared/assets/images/whale4.png"

export const Popup = memo((props) => {
	const { children, className, visible = true, text, fixed = false } = props

	const mods = {
		[cls.Visible]: visible,
	}

	if (fixed) {
		return (
			<div className={classNames(cls.PopupFixed, mods, [className])}>
				<img src={whale} alt="whale" className={cls.Whale} />
				{children}
			</div>
		)
	}

	return (
		<div className={classNames(cls.Popup, mods, [className])}>
			<img src={whale} alt="whale" className={cls.Whale} />
			<div className={cls.Text}>{text}</div>
		</div>
	)
})
