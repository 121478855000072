import { classNames } from "shared/lib/classNames/classNames"
import cls from "../InstallWidget.module.scss"
import { Button } from "shared/ui/Button"
import logo from "shared/assets/icons/AppIcon2.png"
import time from "shared/assets/icons/IconTime.png"
import { Cross } from "shared/ui/Cross"

export const IosInstall = (props) => {
	const { onCloseInstall, cross } = props
	return (
		<div className={classNames(cls.InstallWidget, {}, [])}>
			{cross ? <Cross onClose={onCloseInstall} /> : null}
			<div className={cls.ImageContainer}>
				<img className={cls.Image} alt="TagLife" src={logo} />
				<img className={cls.Icon} alt="TagLife" src={time} />
			</div>
			<div className={cls.Text}>
				Приложение для твоего телефона в разработке
			</div>
			<Button
				onClick={onCloseInstall}
				className={cls.Button}
				text={"Продолжить пользоваться в браузере"}
			/>
		</div>
	)
}
