import { FloatModal } from "shared/ui/FloatModal"
import { Text, TextAlign, TextSize, TextWeight } from "shared/ui/Text/Text"
import cls from "./InstallFloat.module.scss"
import whale from "shared/assets/images/whale4.png"
import { Button } from "shared/ui/Button"
import logo from "shared/assets/icons/AppIcon2.png"
import install from "shared/assets/icons/IconInstall.png"
import React from "react"

export const InstallFloat = (props) => {
	const { closeInstallFloat, onInstall } = props

	return (
		<FloatModal onClose={closeInstallFloat}>
			<div className={cls.ImageContainer}>
				<img className={cls.Image} alt="TagLife" src={logo}></img>
				<img className={cls.Icon} alt="TagLife" src={install}></img>
			</div>
			<Text
				className={cls.TitleTextFloat}
				size={TextSize.XL}
				align={TextAlign.LEFT}
				weight={TextWeight.BOLD}
			>
				Воспользуйся всеми функциями приложения!
			</Text>
			<Text
				className={cls.TextFloat}
				align={TextAlign.LEFT}
				weight={TextWeight.MED}
			>
				Приложение удобнее, быстрее и в нём больше полезного
			</Text>
			<Button
				onClick={onInstall}
				className={cls.FloatButton}
				text={"Установить TagLife"}
			></Button>
		</FloatModal>
	)
}
