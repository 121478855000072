import { classNames } from "shared/lib/classNames/classNames"
import cls from "./FriendsItem.module.scss"
import { useState } from "react"
import {
	Text,
	TextAlign,
	TextColor,
	TextSize,
	TextWeight,
} from "shared/ui/Text/Text"
import chevron from "shared/assets/icons/chevron-right.svg"
import { useNavigate } from "react-router-dom"
import { animated } from "@react-spring/web"

export const FriendsItem = (props) => {
	const { id, style, avatar, name, nickname } = props

	const navigate = useNavigate()

	const [active, setActive] = useState(false)

	const mouseDown = () => {
		setActive(true)
	}

	const mouseUp = () => {
		setActive(false)
	}

	const navigatToProfile = () => {
		if (nickname) {
			navigate(`/${nickname}`)
		} else if (id) {
			navigate(`/${id}`)
		}
	}

	return (
		<animated.div
			className={classNames(cls.FriendsItem, {}, [])}
			onClick={navigatToProfile}
			onTouchStart={mouseDown}
			onTouchEnd={mouseUp}
			style={
				active
					? {
							...style,
							transition: "all 0.05s linear",
							transform: "translate3d(3px,3px,0)",
							boxShadow: "0px 0px",
					  }
					: style
			}
		>
			<img src={avatar} alt="avatar" className={cls.avatar} />
			<div className={cls.nameContainer}>
				<Text weight={TextWeight.SBOLD} align={TextAlign.LEFT}>
					{name}
				</Text>
				<Text
					size={TextSize.S}
					color={TextColor.TERBLACK}
					align={TextAlign.LEFT}
				>
					{nickname ? `@${nickname}` : `@${id}`}
				</Text>
				<img className={cls.chevron} src={chevron} alt="chevron" />
			</div>
			{/* <div className={cls.receivedContainer}>
                <img className={cls.cup} src={cup} alt="cup"></img>
                <Text weight={TextWeight.BOLD} align={TextAlign.LEFT}>{received}</Text>
            </div> */}
		</animated.div>
	)
}
