import cls from "./Card.module.scss"
import { Round } from "shared/ui/Round"
import background from "../../../shared/assets/images/cardBack.jpg"
import { animated } from "@react-spring/web"
import { useEffect, useState } from "react"
import { Loader } from "shared/ui/Loader"
import { useDispatch, useSelector } from "react-redux"
import reject from "shared/assets/images/reject.png"
import accept from "shared/assets/images/accept.png"
import want from "shared/assets/images/want.png"
import { authActions } from "features/anonymousAuth"
import { parser } from "shared/lib/parser/parser"
import { classNames } from "shared/lib/classNames/classNames"
import imgBlocked from "shared/assets/images/blocked.png"
import imgBlockedWhite from "shared/assets/images/blocked-white.png"
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "app/providers/FirebaseProvider/ui/FirebaseProvider"
import { Text, TextColor, TextSize, TextWeight } from "shared/ui/Text/Text"
import { useNavigate } from "react-router-dom"
import React from "react"

export const iconEnum = {
	REJECT: reject,
	WANT: want,
	ACCEPT: accept,
}

export const swipeTextEnum = {
	REJECT: "Пропустить",
	WANT: "Хочу",
	ACCEPT: "Сделано",
}

export const sizeEnum = {
	SMALL: "small",
	LARGE: "large", //дефолтная карточка
	MICRO: "micro",
}

export const ColorEnum = {
	// Список цветов
	FIRST: "First",
	SECOND: "Second",
	THIRD: "Third",
	FOURTH: "Fourth",
	FIFTH: "Fifth",
	SIXTH: "Sixth",
	SEVENTH: "Seventh",
	EIGHTH: "Eighth",
	NINTH: "Ninth",
	TENTH: "Tenth",
	ELEVENTH: "Eleventh",
	TWELFTH: "Twelfth",
	THIRTEENTH: "Thirteenth",
	FOURTEENTH: "Fourteenth",
	YELLOW: "Yellow",
	PURPLE: "Purple",
	BLUE: "Blue",
	GREEN: "Green",
	PINK: "Pink",
	ORANGE: "Orange",
	WHITE: "White",
	BLACK: "Black",
}

export const BlockedCard = {
	BLACK: "blockedBlack",
	WHITE: "blockedWhite",
}

export const Card = (props) => {
	let {
		className,
		text,
		image,
		size,
		status,
		style,
		color,
		id,
		handlers,
		gradient,
		icon,
		changeState,
		blocked,
		disabledRound,
		readonly,
		userId,
		switcher,
	} = props

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [isLoadedImg, setIsLoadedImg] = useState(false)
	const [userData, setUserData] = useState({})

	const myFriends = useSelector((state) => state.auth.friends)
	const myId = useSelector((state) => state.auth.id)

	if (blocked === "blockedBlack") {
		text = "Закрытое достижение"
		image = imgBlocked
		color = "BLACK"
	} else if (blocked === "blockedWhite") {
		// image = imgBlockedWhite
		text = "Закрытое достижение"
		color = "WHITE"
	}

	useEffect(() => {
		const img = new Image()
		img.onload = () => {
			dispatch(authActions.isLoaded())
			setIsLoadedImg(true)
		}
		img.src = image
	}, [image])

	useEffect(() => {
		if (userId) {
			const q = query(collection(db, "Auth"), where("id", "==", userId))
			getDocs(q)
				.then((querySnapshot) => {
					querySnapshot.forEach((docs) => {
						// const docRef = doc(db, "Auth", docs.id);
						setUserData(docs.data())
					})
				})
				.catch((error) => {
					console.error("Ошибка при выполнении запроса:", error)
				})
		}
	}, [])

	const openProfile = () => {
		if (userData.nickname) {
			navigate(`/${userData.nickname}`)
		} else {
			navigate(`/${userData.id}`)
		}
	}

	const addFriend = () => {
		if (myFriends.includes(userId)) {
			dispatch(authActions.deleteFriend(userId))
		} else {
			dispatch(authActions.addFriend(userId))
		}
		console.log(myFriends)
	}

	const rndColor = ColorEnum[color]

	let parsedText = parser(text)

	const mods = {
		[cls[rndColor]]: true,
	}

	const imageMods = {
		[cls.blockedImage]: blocked === "blockedWhite",
	}

	const textMods = {
		[cls[blocked]]: blocked,
	}

	const friendsButtonMods = {
		[cls.friend]: myFriends.includes(userId),
	}

	if (size === "small") {
		return (
			<animated.div
				style={style}
				className={classNames(cls.Card, mods, [cls.SmallCard])}
				// variants={variants}
				// initial={initial}
				// animate={animate}
				// exit={exit}
				// transition={transition}
			>
				{/* {isLoadedImg ? */}
				<>
					<div
						className={classNames(cls.Text, textMods, [
							cls.SmallText,
						])}
					>
						<b>{parsedText[0]}</b> {parsedText[1]}
					</div>
					{isLoadedImg ? (
						<img
							className={classNames(cls.CardImage, imageMods, [])}
							alt="cardImage"
							src={image}
						/>
					) : (
						<Loader size={"small"} className={cls.SmallLoader} />
					)}
				</>
				{!blocked && switcher !== "created" ? (
					<Round
						disabledRound={disabledRound}
						status={status}
						changeState={
							changeState ? () => changeState(status, id) : null
						}
						readonly={readonly}
					/>
				) : null}
				<img
					className={cls.SmallBackground}
					src={background}
					alt="back"
				/>
			</animated.div>
		)
	} else if (size === "large") {
		return (
			<animated.div
				{...handlers}
				style={style}
				className={classNames(cls.Card, mods, [])}
			>
				<>
					<div className={cls.Container} style={gradient}>
						{icon ? (
							<img
								className={cls.SwipeIcon}
								src={icon}
								alt="icon"
							></img>
						) : null}
					</div>
					<div className={cls.Text}>
						<b>{parsedText[0]}</b> {parsedText[1]}
					</div>
					{userData.avatar ? (
						<div className={cls.UserInfo}>
							<div
								onClick={openProfile}
								className={cls.UserInfoAvatar}
							>
								<img
									className={cls.UserInfoAvatar}
									src={userData.avatar}
									alt="avatar"
								></img>
							</div>
							<Text
								className={cls.UserInfoName}
								color={TextColor.SECBLACK}
								weight={TextWeight.SBOLD}
								size={TextSize.XS}
								onClick={openProfile}
							>
								{userData.nickname
									? `@${userData.nickname}`
									: `@${userData.id}`}
							</Text>
							{myId !== userId ? (
								<button
									className={classNames(
										cls.AddFriendButton,
										friendsButtonMods,
										[]
									)}
									onClick={addFriend}
								>
									{myFriends.includes(userId)
										? "В друзьях"
										: "В друзья"}
								</button>
							) : null}
						</div>
					) : null}
					{isLoadedImg ? (
						<img
							className={cls.CardImage}
							alt="cardImage"
							src={image}
						/>
					) : (
						<div
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Loader />
						</div>
					)}
				</>
				<img className={cls.Background} src={background} alt="back" />
			</animated.div>
		)
	} else if (size === "micro") {
		return (
			<animated.div
				className={classNames(cls.MicroCard, mods, [className])}
			>
				<>
					<div className={classNames(cls.MicroText, textMods, [])}>
						<b>{parsedText[0]}</b> {parsedText[1]}
					</div>
					{isLoadedImg ? (
						<img
							className={cls.MicroCardImage}
							alt="cardImage"
							src={image}
						/>
					) : (
						<Loader size={"small"} className={cls.MicroLoader} />
					)}
				</>
				<img
					className={cls.MicroBackground}
					src={background}
					alt="back"
				/>
			</animated.div>
		)
	}
}
