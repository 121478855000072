import { createSlice } from "@reduxjs/toolkit"
import { fetchCollectionData } from "entities/Collection/services/fetchCollectionData"

const initialState = {
	collections: [], // карточка
	status: null,
	error: null,
	parsedCollections: [],
	announceCollections: [],
}

const collectionSlice = createSlice({
	name: "collection",
	initialState,
	reducers: {
		setParsedCollections(state, action) {
			state.parsedCollections = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCollectionData.pending, (state) => {
				state.status = "loading"
				state.error = null
			})
			.addCase(fetchCollectionData.fulfilled, (state, action) => {
				action.payload.map((item, i) => {
					if (item.announce) {
						state.announceCollections.push(item)
						action.payload.splice(i, 1)
					}
				})
				state.collections = action.payload
				// console.log(state.collections)
				state.status = "resolved"
			})
			.addCase(fetchCollectionData.rejected, () => {
				throw new Error("Error from fetchCollectionData")
			})
	},
})

export default collectionSlice.reducer
export const { actions: collectionActions } = collectionSlice
