import App from "app/App"
import { fetchCardData } from "entities/Card"
import { fetchCollectionData } from "entities/Collection/services/fetchCollectionData"
import { getStatus, getUid } from "features/anonymousAuth"
import { getMode } from "features/modeLogic"
import { modeActions } from "features/modeLogic/model/slice/modeLogicSlice"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { TagLifeLoader } from "shared/ui/TagLifeLoader"
import React from "react"

export const LoadDataProvider = () => {
	const uid = useSelector(getUid)
	const dispatch = useDispatch()
	const location = useLocation()
	const statusCards = useSelector(getStatus)
	const statusCollection = useSelector((state) => state.collection.status)

	const mode = useSelector(getMode)

	const [installPrompt, setInstallPrompt] = useState() // установка приложения

	useEffect(() => {
		// получение карточек после anonymous auth
		if (uid && location.pathname !== "/special") {
			dispatch(fetchCollectionData())
			console.log("fetching collection data")
			dispatch(fetchCardData("cards"))
			console.log(uid)
		} else if (uid && location.pathname == "/special") {
			console.log(uid)
			dispatch(modeActions.setMode("special"))
			dispatch(fetchCardData("special"))
		}
	}, [dispatch, uid])

	useEffect(() => {
		//запуск установки
		// let install = localStorage.getItem('install')
		if (!window.matchMedia("(display-mode: standalone)").matches) {
			const ua = navigator.userAgent
			if (/android/i.test(ua)) {
				dispatch(modeActions.setAgent("android"))
			} else if (/iPad|iPhone|iPod/.test(ua)) {
				dispatch(modeActions.setAgent("ios"))
			} else {
				dispatch(modeActions.setAgent("browser"))
			}

			window.addEventListener("beforeinstallprompt", (e) => {
				dispatch(modeActions.setInstallPossibility(true))

				e.preventDefault()
				setInstallPrompt(e)

				console.log(`'beforeinstallprompt' event was fired.`)
			})

			window.addEventListener("appinstalled", () => {
				//
				setInstallPrompt("installed")
				dispatch(modeActions.setMode("installed"))
			})
		}
	}, [])

	useEffect(() => {
		// проверка возможности установки пушей (не срабатывает при уже установленном + ios)
		if ("Notification" in window) {
			if (Notification.permission === "default") {
				dispatch(modeActions.setPushPossibility(true))
			} else {
				dispatch(modeActions.setPushPossibility(false))
			}
		} else {
			dispatch(modeActions.setPushPossibility(false))
		}
	}, [])

	if (mode == "special") {
		return (
			<>
				{statusCards === "resolved" ? (
					<App installPrompt={installPrompt} />
				) : (
					<TagLifeLoader />
				)}
			</>
		)
	} else {
		return (
			<>
				{statusCards === "resolved" &&
				statusCollection === "resolved" ? (
					<App installPrompt={installPrompt} />
				) : (
					<TagLifeLoader />
				)}
			</>
		)
	}
}
