import { AndroidInstall } from "../AndroidInstall/AndroidInstall"
import { PwaInstall } from "../PwaInstall/PwaInstall"
import { Subscribe } from "../Subscribe/Subscribe"

export const Special = (props) => {
	const {
		installPossibility,
		agent,
		onCloseInstall,
		onDownload,
		onInstall,
		onSubscribe,
	} = props

	if (installPossibility) {
		return (
			<PwaInstall
				text={"Чтобы продолжить, TagLife нужно установить"}
				onCloseInstall={onCloseInstall}
				onInstall={onInstall}
			/>
		)
	} else {
		if (agent === "android") {
			return (
				<AndroidInstall
					text={"Чтобы продолжить, TagLife нужно скачать"}
					onCloseInstall={onCloseInstall}
					onDownload={onDownload}
				/>
			)
		} else {
			return <Subscribe onSubscribe={onSubscribe} />
		}
	}
}
