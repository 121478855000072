import cls from "./NavButton.module.scss"
import { Link } from "react-router-dom"
import { classNames } from "shared/lib/classNames/classNames"

export const NavButton = (props) => {
	const { to, title, imgsrc, location, newContent, onActive } = props

	let disable
	if (to === location.pathname) {
		disable = cls.Disable
	}

	return (
		<Link
			to={to}
			className={classNames(cls.Link, {}, [disable])}
			onClick={onActive}
		>
			<div
				className={`${cls.Container} ${
					to === location.pathname ? cls.ActiveContainer : ""
				}`}
			>
				{newContent ? <div className={cls.RedDot} /> : null}
				<img className={cls.Image} alt="home" src={imgsrc} />
			</div>
			<div className={to === location.pathname ? cls.Active : cls.Title}>
				{title}
			</div>
		</Link>
	)
}
