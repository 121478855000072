import { Navbar } from "widgets/Navbar"
import { RouteProvider } from "./providers/RouteProvider"
import { useSelector } from "react-redux"
import SpecialPage from "pages/SpecialPage"

import "./styles/App.scss"
import { InstallWidget } from "widgets/InstallWidget/ui/InstallWidget"
import { getMode } from "features/modeLogic"

function App(props) {
	const { installPrompt } = props
	const mode = useSelector(getMode)

	const navVisible = useSelector((state) => state.nav.navVisible)

	// const messaging = getMessaging()  // раскомментить в прод

	// if (isSupported()) {
	//     console.log(isSupported())

	//     onMessage(messaging, (payload) => {  // раскомментить в прод
	//         console.log('Message received. ', payload);
	//         // ...
	//     });
	// }

	let app
	if (mode === "app") {
		app = (
			<>
				<RouteProvider installPrompt={installPrompt} />
				{navVisible ? <Navbar /> : null}
			</>
		)
	} else if (mode === "special") {
		app = <SpecialPage installPrompt={installPrompt} />
	} else if (mode === "install" || mode === "installed") {
		app = <InstallWidget installPrompt={installPrompt} />
	}

	return (
		<div className="app normal">
			<div className="main">{app}</div>
		</div>
	)
}

export default App
