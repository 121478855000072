import { classNames } from "shared/lib/classNames/classNames"
import logo from "shared/assets/icons/AppIcon2.png"
import success from "shared/assets/icons/IconSuccess.png"
import cls from "../InstallWidget.module.scss"

export const Installed = () => {
	return (
		<div className={classNames(cls.InstallWidget, {}, [])}>
			<div className={cls.ImageContainer}>
				<img className={cls.Image} alt="TagLife" src={logo} />
				<img className={cls.Icon} alt="TagLife" src={success} />
			</div>
			<div className={cls.Text}>
				3...2...1... Открой TagLife с главного экрана{" "}
			</div>
		</div>
	)
}
