import { Route, Routes } from "react-router-dom"
import { Suspense } from "react"
import { NotFoundPage } from "pages/NotFoundPage"
import { useSelector } from "react-redux"
import { MomentPage } from "pages/MomentPage"
import { RequestPage } from "pages/RequestPage"
import { CollectionPage } from "pages/CollectionPage"
import SpecialPage from "pages/SpecialPage"
import SingleCollectionPage from "pages/SingleCollectionPage/ui/SingleCollectionPage"
import { SettingsPageAsync } from "pages/SettingsPage/ui/SettingsPage.async"
import { Loader } from "shared/ui/Loader"
import FriendsPage from "pages/FriendsPage/FriendsPage"
import { AdminPageAsync } from "pages/AdminPage/ui/AdminPage.async"
import React from "react"

export const RouteProvider = (props) => {
	const { installPrompt } = props
	const collections = useSelector((state) => state.collection.collections)

	return (
		<Suspense
			fallback={
				<div
					style={{
						height: "100%",
						display: "flex",
						alignItems: "center",
					}}
				>
					<Loader />
				</div>
			}
		>
			<Routes>
				<Route
					path={"/"}
					element={<MomentPage installPrompt={installPrompt} />}
				/>
				<Route path={"/suggest"} element={<RequestPage />} />
				<Route path={"/admin"} element={<AdminPageAsync />} />
				<Route path={`/:id`} element={<CollectionPage />} />
				<Route path={"/special"} element={<SpecialPage />} />
				<Route path={"/settings"} element={<SettingsPageAsync />} />
				<Route path={"/friends"} element={<FriendsPage />} />
				{collections.map((item) => {
					return (
						<Route
							key={item.id}
							path={`/:id/${item.link}`}
							element={<SingleCollectionPage collection={item} />}
						/>
					)
				})}
				<Route path={"*"} element={<NotFoundPage />} />
			</Routes>
		</Suspense>
	)
}
