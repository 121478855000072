import HeaderCollection from "./HeaderCollection/HeaderCollection"
import GridCollection from "./GridCollection/GridCollection"
import { useDispatch, useSelector } from "react-redux"
import cls from "./CollectionPage.module.scss"
import {
	getAcceptedMoments,
	getAllCards,
	getRejectedMoments,
	getWantedMoments,
} from "features/anonymousAuth"
import { useCallback, useEffect, useRef, useState } from "react"
import { ButtonProfile } from "widgets/ButtonProfile/ButtonProfile"
import { CollectionContainer } from "./CollectionContainer/CollectionContainer"
import { navActions } from "entities/NavMenu"
import { shareFunc } from "shared/lib/shareFunc/shareFunc"
import { useNavigate, useParams } from "react-router-dom"
import { Text, TextColor, TextSize, TextWeight } from "shared/ui/Text/Text"
import { Button } from "shared/ui/Button"
import inside from "shared/assets/icons/inside.svg"
import { DragDrop } from "widgets/DragDrop/DragDrop"
import { fetchOtherProfileData } from "features/otherProfileData/model/services/fetchOtherProfileData"
import OtherProfilePage from "./OtherProfilePage/OtherProfilePage"
import { Credits } from "widgets/Credits/Credits"
import { Popup } from "shared/ui/Popup/Popup"
import { copyTextToClipboard } from "shared/lib/copyTextToTextClipboard/copyTextToTextClipboard"
import { AnnounceFloat } from "./AnnounceFloat/AnnounceFloat"
import {
	getAcceptedCardData,
	getCreatedCardData,
	getSelectCardsInCollection,
	getWantedCardData,
} from "app/providers/LoadDataProvider/ui/selectors"
import { WantContainer } from "./WantContainer/WantContainer"
import copyIcon from "shared/assets/icons/copyIcon.svg"
import share from "shared/assets/icons/share-32.svg"
import React from "react"

const CollectionPage = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const announceCollections = useSelector(
		(state) => state.collection.announceCollections
	)

	const parsedCollections = useSelector(getSelectCardsInCollection)

	let { id } = useParams()

	const userId = useSelector((state) => state.auth.id)
	const name = useSelector((state) => state.auth.name)
	const avatar = useSelector((state) => state.auth.avatar)
	const descr = useSelector((state) => state.auth.descr)
	const nickname = useSelector((state) => state.auth.nickname)
	const background = useSelector((state) => state.auth.background)

	const [showAnnounceFloat, setShowAnnounceFloat] = useState(false)

	const [switcher, setSwitcher] = useState(null)
	const [fixedHeader, setFixedHeader] = useState(true)
	const [dragdrop, setDragdrop] = useState(false)

	const [visible, setVisible] = useState(false)

	const [secondSwitcher, setSecondSwitcher] = useState(null)

	useEffect(() => {
		let secondSwitcher = sessionStorage.getItem("secondSwitcher")

		if (secondSwitcher) {
			setSecondSwitcher(secondSwitcher)
		} else {
			sessionStorage.setItem("secondSwitcher", "want")
			setSecondSwitcher("want")
		}
	}, [])

	useEffect(() => {
		if (id == userId) {
			dispatch(navActions.setVisible(true))
		}
		if (id === nickname) {
			dispatch(navActions.setVisible(true))
		}
	}, [])

	const wantedCardData = useSelector(getWantedCardData)
	const acceptedCardData = useSelector(getAcceptedCardData)
	const createdCardData = useSelector(getCreatedCardData)

	const myAcceptedMoments = useSelector(getAcceptedMoments) // для баннера снизу
	const myWantedMoments = useSelector(getWantedMoments)
	const myRejectedMoments = useSelector(getRejectedMoments)
	const myAllMoments = myAcceptedMoments.concat(
		myWantedMoments,
		myRejectedMoments
	)

	let header

	if (switcher === "accepted") {
		header = "Достигнуто"
	} else if (switcher === "created") {
		header = "Создано"
	}

	let counterCreate = createdCardData.length
	// let counterAccept = acceptedCardData.length

	const onToggleWant = () => {
		setSecondSwitcher("want")
		sessionStorage.setItem("secondSwitcher", "want")
	}

	const onToggleCol = () => {
		setSecondSwitcher("collection")
		sessionStorage.setItem("secondSwitcher", "collection")
	}

	const onSwitchCreated = () => {
		setSwitcher("created")
		setFixedHeader(true)
	}

	const onSwitchAccepted = () => {
		setSwitcher("accepted")
		setFixedHeader(true)
	}

	const onSwitchBack = () => {
		setSwitcher(null)
		setFixedHeader(true)
		dispatch(navActions.setVisible(true))
	}

	const closeDragdrop = () => {
		setTimeout(() => {
			setDragdrop(false)
		}, 100)
	}

	const timeoutWhaleCopyRef = useRef(null) // реф для таймаута говорящего кита
	const onSpeak = useCallback(() => {
		// ф-ция говорящего кита
		clearTimeout(timeoutWhaleCopyRef.current)
		timeoutWhaleCopyRef.current = null
		setVisible(true)
		timeoutWhaleCopyRef.current = setTimeout(() => {
			setVisible(false)
		}, 1000)
	}, [])

	if (userId != id) {
		if (id !== nickname) {
			return <OtherProfilePage />
		}
	}

	const navigateToSettings = () => {
		navigate("/settings")
	}

	const copyText = (text) => {
		copyTextToClipboard(text)
		onSpeak()
	}

	const closeAnnounceFloat = () => {
		setShowAnnounceFloat(false)
	}

	const navigateToSuggest = () => {
		navigate("/suggest")
	}

	const openAnnounceFloat = () => {
		setShowAnnounceFloat(true)
	}

	return (
		<>
			{showAnnounceFloat ? (
				<AnnounceFloat
					closeAnnounceFloat={closeAnnounceFloat}
					navigateToSuggest={navigateToSuggest}
				/>
			) : null}
			<div className={cls.CollectionPage}>
				<Popup visible={visible} text={"Ник скопирован"} />
				{!switcher ? (
					<div className={cls.ProfileContainer}>
						<Button className={cls.ShareButton} onClick={shareFunc}>
							<img
								className={cls.Share}
								src={share}
								alt="share"
							/>
						</Button>
						<Button
							onClick={() => setDragdrop(!dragdrop)}
							className={cls.Settings}
						>
							<img
								style={{ pointerEvents: "none", width: "24px" }}
								src={inside}
								alt="cross"
							></img>
						</Button>
						<img
							className={cls.background}
							alt="background"
							src={background}
						></img>
						<img
							onClick={navigateToSettings}
							className={cls.Avatar}
							alt="avatar"
							src={avatar}
						></img>
						<Text
							className={cls.name}
							size={TextSize.L}
							weight={TextWeight.BOLD}
						>
							{name == "Мечтатель"
								? "Здесь будет твоё имя"
								: name}
						</Text>
						<Text
							onClick={() =>
								copyText(nickname ? `@${nickname}` : `@${id}`)
							}
							className={cls.nickname}
							size={TextSize.S}
							color={TextColor.TERBLACK}
						>
							<div style={{ display: "flex" }}>
								{nickname ? `@${nickname}` : `@${id}`}
								<img
									style={{ width: "16px", marginLeft: "4px" }}
									src={copyIcon}
									alt="copy"
								></img>
							</div>
						</Text>
						<Text
							className={cls.description}
							weight={TextWeight.REG}
						>
							{descr !== null
								? descr
								: "А тут твой текст. Настрой свой профиль в  ···  сверху ↑"}
						</Text>
					</div>
				) : null}
				{!switcher ? (
					<ButtonProfile
						className={cls.ButtonProfile}
						counterAccept={acceptedCardData.length}
						onSwitchAccepted={onSwitchAccepted}
						counterWant={counterCreate}
						onSwitchWanted={onSwitchCreated}
						share={shareFunc}
						secondSwitcher={secondSwitcher}
						onToggleWant={onToggleWant}
						onToggleCol={onToggleCol}
					/>
				) : null}
				{switcher === "created" ? (
					<GridCollection
						collection={createdCardData}
						switcher={switcher}
						header={header}
						counterAccept={acceptedCardData.length}
						counterWant={counterCreate}
						onSwitchBack={onSwitchBack}
					/>
				) : null}
				{switcher === "accepted" ? (
					<GridCollection
						collection={acceptedCardData}
						switcher={switcher}
						header={header}
						counterAccept={acceptedCardData.length}
						counterWant={counterCreate}
						onSwitchBack={onSwitchBack}
					/>
				) : null}
				{!switcher ? (
					<>
						{secondSwitcher === "collection" ? (
							<CollectionContainer
								className={cls.CollectionContainer}
								collections={parsedCollections}
								announceCollections={announceCollections}
								openAnnounceFloat={openAnnounceFloat}
							/>
						) : (
							<WantContainer
								myAllMoments={myAllMoments}
								wantedCardData={wantedCardData}
							/>
						)}
					</>
				) : null}
				{dragdrop ? (
					<DragDrop
						closeDragdrop={closeDragdrop}
						nickname={nickname}
						id={userId}
						onSpeak={onSpeak}
					/>
				) : null}
			</div>
		</>
	)
}

export default CollectionPage
